import { combineReducers, configureStore } from "@reduxjs/toolkit";
// Reducers
import reservationsReducer from 'store/reservationsRequests/reservationsRequestsSlice';
import appReducer from 'store/app/appSlice';
import sponsorsReducer from 'store/sponsors/sponsorsSlice';
import competitionsReducer from 'store/competitions/competitionsSlice';
import pricesReducer from 'store/prices/pricesSlice';
import templatesReducer from 'store/templates/templatesSlice';
import usersReducer from 'store/users/usersSlice';
import authReducer from 'store/auth/authSlice';
import assetsReducer from 'store/assets/assetsSlice';
import reviewsReducer from 'store/reviews/reviewsSlice';
// middlewares
import errorMiddleware from 'middlewares/errorMiddleware';

const rootReducer = combineReducers({
  app: appReducer,
  reservations: reservationsReducer,
  sponsors: sponsorsReducer,
  competitions: competitionsReducer,
  prices: pricesReducer,
  templates: templatesReducer,
  users: usersReducer,
  auth: authReducer,
  assets: assetsReducer,
  reviews: reviewsReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(errorMiddleware)
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch']; 
