import React from 'react';
// MUI
import { Tooltip, Typography } from '@mui/material';
import {
  Instagram, Facebook,
  YouTube, PhoneInTalkOutlined
} from '@mui/icons-material';
// styles
import classes from './styles.module.scss';

const Footer:React.FC = () => {
  const year = new Date().getFullYear();

  return (
    <footer className={[classes.footer, 'container'].join(' ')}>
      <Typography sx={{ color: '#fff', fontSize: '20px', fontWeight: 500 }}>{`© ${year} Трофейна долина`}</Typography>

      <div className={classes.socials}>
        <Tooltip title="Facebook">
          <a href="https://www.facebook.com/groups/221419299367629" target="_blank" rel="noreferrer">
            <Facebook className={classes.icon} />
          </a>
        </Tooltip>
        <Tooltip title="Instagram">
          <a href="https://www.instagram.com/carp_team_lviv" target="_blank" rel="noreferrer">
            <Instagram className={classes.icon} />
          </a>
        </Tooltip>
        <Tooltip title="YouTube">
          <a href="https://www.youtube.com/c/CarpTeamLviv" target="_blank" rel="noreferrer">
            <YouTube className={classes.icon} />
          </a>
        </Tooltip>
        <Tooltip title="Viber">
          <a href="https://invite.viber.com/?g2=AQB4AVH47RbdB03fa6889GgSapNpbYlm3o0cYumncK7sTo71E%2FlPp8mJJj54Ld7j" target="_blank" rel="noreferrer">
            <PhoneInTalkOutlined className={classes.icon} />
          </a>
        </Tooltip>
      </div>
    </footer>
  );
}

export default Footer;
