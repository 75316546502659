import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';

const ReviewSkeleton:FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
      <Skeleton
        variant="circular"
        sx={{ width: '120px', height: '120px', mb: 2 }}
      />
      <Skeleton sx={{ width: '220px', height: '25px' }} />
      <Skeleton sx={{ width: '120px', height: '25px' }} />
      <Skeleton sx={{ width: '360px', height: '25px' }} />
    </Box>
  )
}

export default ReviewSkeleton;