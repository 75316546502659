import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
// Async
import { fetchSponsors } from 'store/sponsors/sponsorsAsync';
// Selectors
import { selectSponsors } from 'store/sponsors/sponsorsSelectors';
// Models
import ISponsor from 'models/Sponsor';
// Components
import Title from 'components/Title';
// MUI
import { makeStyles } from '@mui/styles';
import { Skeleton } from '@mui/material';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate } from 'utilities/Framer';
import { Pagination } from 'swiper';

const Sponsors:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const sponsors: ISponsor[] = useSelector(selectSponsors);
  const handleCountCards = () => {
    return window.innerWidth >= 1240 ? 4 :  window.innerWidth >= 720 ? 3 : window.innerWidth >= 600 ? 2.5 : 1.5;
  }

  const [countCard, setCountCard] = useState<number>(handleCountCards());

  const handlerScreenWidth = () => {
    setCountCard(handleCountCards());
  }

  useEffect(() => {
    dispatch(fetchSponsors({}));
    window.addEventListener('resize', handlerScreenWidth);

    return () => {
      window.removeEventListener('resize', handlerScreenWidth);
    }
    // eslint-disable-next-line
  }, []);

  if (!sponsors || !sponsors.length) return null;
  return (
    <motion.section
      className={[classes.block, 'container'].join(' ')}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <motion.div
        variants={bottomAnimate}
        custom={1}
        className={classes.title}
      >
        <Title>Спонсори</Title>
      </motion.div>
      <Swiper
        style={{ padding: '35px 5px' }}
        spaceBetween={24}
        slidesPerView={countCard}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {!!sponsors.length ? (
          sponsors.map((sponsor: ISponsor) => (
            <SwiperSlide key={sponsor._id} className={classes.card}>
              <a href={sponsor.url} target="_blank" rel="noreferrer">
                <img src={sponsor.logo?.path} alt={sponsor.name} className={classes.img} />
                <h4 className={classes.subtitle}>{sponsor.name}</h4>
              </a>
            </SwiperSlide>
          ))
        ) : (
          Array.from({length: 4}, (_, i) => i).map(item => (
            <SwiperSlide key={item} className={classes.card}>
              <Skeleton
                variant="circular"
                className={classes.skeleton}
              />
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </motion.section>
  );
}

export default Sponsors;

const useStyles = makeStyles({
  block: {
    padding: '16px 0', 
    '@media (min-width: 600px)': {
      padding: '32px 0', 
    },
    '@media (min-width: 900px)': {
      paddingTop: '40px', 
      paddingBottom: '40px',
    },
  },
  title: {
    paddingLeft: '16px', 
    paddingRight: '16px',
    '@media (min-width: 600px)': {
      paddingLeft: '32px', 
      paddingRight: '32px',
    },
    '@media (min-width: 900px)': {
      paddingLeft: '40px', 
      paddingRight: '40px',
    },
  },
  card: {
    transition: 'all 0.3s',
    border: '2px solid transparent',
  },
  img: {
    width: '150px !important',
    height: '150px',
    objectFit: 'cover',
    transition: 'all 0.3s',
    borderRadius: '50%',
    margin: '0 auto',
  },
  subtitle: {
    color: '#fefefe',
    fontSize: '24px',
    textAlign: 'center',
    paddingTop: '16px',
  },
  skeleton: {
    width: '200px',
    height: '200px !important',
  }
});
