import { FC } from 'react';
// Models
import Review from 'models/Review';
// MUI
import { makeStyles } from '@mui/styles';
import { Star, StarBorder } from '@mui/icons-material';

type Props = {
  review: Review;
}

const ReviewItem:FC<Props> = ({ review }) => {
  const classes = useStyles();

  const rating = new Array(review.rating).fill(true).concat(new Array(5).fill(false)).slice(0, 5);

  return (
    <div className={classes.card}>
      <img src={review.profile_photo_url} alt={review.author_name} className={classes.avatar} />
      <h3 className={classes.title}>{review.author_name}</h3>
      <div className={classes.rating}>
        {rating.map((rate, index) => {
          const Icon = rate ? Star : StarBorder;
          return (
            <Icon key={`start-${index}`} sx={{ color: '#FDCC0D' }} fontSize="small" />
          )
        })}
      </div>
      <p className={classes.text}>{review.text}</p>
    </div>
  );
}

export default ReviewItem;

const useStyles = makeStyles({
  card: {
    cursor: 'default',
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px'
  },
  avatar: {
    width: '120px !important',
    height: '120px',
    border: '2px solid #fff',
    borderRadius: '50%',
    objectFit: 'cover',
    marginBottom: '20px',
  },
  rating: {

  },
  title: {
    color: '#fff',
    fontSize: '14px',
    '@media (min-width: 600px)': {
      fontSize: '16px',
    }
  },
  text: {
    color: '#fff',
    fontSize: '14px',
    textAlign: 'center',
    maxWidth: '80%',
    '@media (min-width: 600px)': {
      fontSize: '16px',
    }
  },
})