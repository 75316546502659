import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
// Async
import { fetchReviews } from 'store/reviews/reviewsAsync';
// Selectors
import { selectReviews } from 'store/reviews/reviewsSelectors';
// Components
import Title from 'components/Title';
import ReviewItem from './ReviewItem';
import ReviewSkeleton from './ReviewSkeleton';
// MUI
import { makeStyles } from '@mui/styles';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
// Framer
import { motion } from 'framer-motion';
import { bottomAnimate } from 'utilities/Framer';

const Reviews:React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const reviews = useSelector(selectReviews);

  useEffect(() => {
    dispatch(fetchReviews({}));
    // eslint-disable-next-line
  }, []);

  return (
    <motion.section
      className={[classes.block, 'container'].join(' ')}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
    >
      <motion.div
        variants={bottomAnimate}
        custom={1}
        className={classes.title}
      >
        <Title>Відгуки</Title>
      </motion.div>
      <Swiper
        style={{ padding: '35px 5px' }}
        spaceBetween={24}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        {!!reviews.length ? (
          reviews.map((review) => (
            <SwiperSlide key={review.uuid}>
              <ReviewItem review={review} />
            </SwiperSlide>
          ))
        ) : (
          <ReviewSkeleton />
        )}
      </Swiper>
    </motion.section>
  );
}

export default Reviews;

const useStyles = makeStyles({
  block: {
    padding: '16px 0', 
    '@media (min-width: 600px)': {
      padding: '32px 0', 
    },
    '@media (min-width: 900px)': {
      paddingTop: '40px', 
      paddingBottom: '40px',
    },
  },
  title: {
    textAlign: 'center',
    paddingLeft: '16px', 
    paddingRight: '16px',
    '@media (min-width: 600px)': {
      paddingLeft: '32px', 
      paddingRight: '32px',
    },
    '@media (min-width: 900px)': {
      paddingLeft: '40px', 
      paddingRight: '40px',
    },
  },
  img: {
    width: '150px !important',
    height: '150px',
    objectFit: 'cover',
    transition: 'all 0.3s',
    borderRadius: '50%',
    margin: '0 auto',
  },
  subtitle: {
    color: '#fefefe',
    fontSize: '24px',
    textAlign: 'center',
    paddingTop: '16px',
  }
});
