interface IConfig {
  [key:string]: {
    production: boolean;
    apiURL: string;
    token: string;
    chatId: string;
  }
}

const config:IConfig = {
  'development': {
    production: false,
    apiURL: 'http://localhost:5555/api/client',
    token: '5541433879:AAEGME7plyv0pcHo_gXNofRTRgogi0YmDQM',
    chatId: '-1001535509809',
  },
  'qa': {
    production: false,
    apiURL: 'https://qa-trophy-valley.lm.r.appspot.com/api/client',
    token: '5541433879:AAEGME7plyv0pcHo_gXNofRTRgogi0YmDQM',
    chatId: '-1001535509809',
  },
  'production': {
    production: true,
    apiURL: 'https://trophy-valley.lm.r.appspot.com/api/client',
    token: '5541433879:AAEGME7plyv0pcHo_gXNofRTRgogi0YmDQM',
    chatId: '-1001535509809',
  }
};

const stage:string = process.env.REACT_APP_STAGE || 'development';

export default config[stage];
