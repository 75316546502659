import React from "react";
// Components
import Title from 'components/Title';
// MUI
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import {
  Instagram, Facebook,
  YouTube, PhoneInTalkOutlined
} from '@mui/icons-material';
// Framer
import { motion } from 'framer-motion';
import { opacityAnimate, topAnimate } from 'utilities/Framer';

const Socials:React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.blockWrapper}>
      <Box className={classes.gradient} />
      <motion.div
        className={[classes.block, 'container'].join(' ')}
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2 }}
      >
        <motion.div
          style={{ display: 'flex', justifyContent: 'center' }}
          variants={topAnimate}
          custom={1}
        >
          <Title>Слідкуйте за нами</Title>
        </motion.div>
        <Box className={classes.socials}>
          <motion.div
            className={classes.socialItem}
            variants={opacityAnimate}
            custom={2}
          >
            <a
              href="https://www.facebook.com/groups/221419299367629"
              target="_blank" rel="noreferrer"
              className={classes.iconWrapper}
              aria-label="facebook"
            >
              <Facebook className={classes.icon} />
            </a>
            <h4 className={classes.socialTitle}>Facebook</h4>
          </motion.div>
          <motion.div
            className={classes.socialItem}
            variants={opacityAnimate}
            custom={3}
          >
            <a
              href="https://www.youtube.com/c/CarpTeamLviv"
              target="_blank" rel="noreferrer"
              className={classes.iconWrapper}
              aria-label="youtube"
            >
              <YouTube className={classes.icon}  />
            </a>
            <h4 className={classes.socialTitle}>YouTube</h4>
          </motion.div>
          <motion.div
            className={classes.socialItem}
            variants={opacityAnimate}
            custom={4}
          >
            <a
              href="https://www.instagram.com/carp_team_lviv"
              target="_blank" rel="noreferrer"
              className={classes.iconWrapper}
              aria-label="instagram"
            >
              <Instagram className={classes.icon} />
            </a>
            <h4 className={classes.socialTitle}>Instagram</h4>
          </motion.div>
          <motion.div
            className={classes.socialItem}
            variants={opacityAnimate}
            custom={5}
          >
            <a
              href="https://invite.viber.com/?g2=AQB4AVH47RbdB03fa6889GgSapNpbYlm3o0cYumncK7sTo71E%2FlPp8mJJj54Ld7j"
              target="_blank" rel="noreferrer"
              className={classes.iconWrapper}
              aria-label="viber"
            >
              <PhoneInTalkOutlined className={classes.icon} />
            </a>
            <h4 className={classes.socialTitle}>Viber</h4>
          </motion.div>
        </Box>
      </motion.div>
    </Box>
  );
}

export default Socials;

const useStyles = makeStyles({
  blockWrapper: {
    position: 'relative',
    padding: '20px 0',
    backgroundImage: 'url(images/mail.jpg)',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  block: {
    position: 'relative',
    zIndex: 1,
    paddingTop: '16px', 
    paddingBottom: '16px',
    '@media (min-width: 600px)': {
      paddingTop: '32px', 
      paddingBottom: '32px',

    },
    '@media (min-width: 900px)': {
      paddingTop: '40px', 
      paddingBottom: '40px',
    },
  },
  socials: {
    paddingTop: '40px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '40px',
    '@media (min-width: 600px)': {
      paddingTop: '60px',
      gap: '60px',
    },
    '@media (min-width: 1240px)': {
      paddingTop: '80px',
      gap: '80px',
    }
  },
  socialItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    cursor: 'pointer',
    width: '40%',
    '@media (min-width: 900px)': {
      width: 'auto',
    },
    '&:hover $iconWrapper': {
      borderColor: '#CFB07A',
    },
    '&:hover $iconWrapper $icon': {
      color: '#CFB07A',
    },
    '&:hover $socialTitle': {
      color: '#CFB07A',
    }
  },
  socialTitle: {
    color: '#fefefe',
    fontWeight: 500,
    transition: 'color 0.3s',
    fontSize: '22px',
  },
  iconWrapper: {
    borderRadius: '50%',
    border: '2px solid #fafafa',
    transition: 'border-color 0.3s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75px',
    height: '75px',
    '@media (min-width: 600px)': {
      width: '120px',
      height: '120px',
    },
    '@media (min-width: 1240px)': {
      width: '150px',
      height: '150px',
    },
  },
  icon: {
    transition: 'color 0.3s',
    color: '#fafafa',
    fontSize: '40px !important',
    '@media (min-width: 600px)': {
      fontSize: '60px !important',
    },
    '@media (min-width: 1240px)': {
      fontSize: '80px !important',
    },
  },
});
