import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchReviews } from "./reviewsAsync";
import IReview from "models/Review";
import { v4 } from "uuid";

interface IState {
  reviews: IReview[];
}

const initialState: IState = {
  reviews: [],
}

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch reviews
      .addCase(fetchReviews.fulfilled, (state, action:PayloadAction<IReview[]>) => {
        state.reviews = action.payload.map(review => ({ ...review, uuid: v4() }));
      })
  }
})

export const reviewsActions = reviewsSlice.actions;
export default reviewsSlice.reducer;